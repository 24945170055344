import * as THREE from 'three'
import GUI from 'lil-gui'
import gsap from 'gsap'

/**
 * Debug
 */
const gui = new GUI()

const parameters = {
    materialColor: '#ffeded',
    particleColor: '#ffeded',
    particlesCount: 200,
    particleSize: 0.03
}

gui.hide()
gui.title('Playground')

/**
 * Modal
 */
const closeModal = document.getElementsByClassName('modal__close');
closeModal[0].addEventListener('click', () => {
    const modal = document.getElementsByClassName('modal');

    modal[0].style.visibility = 'hidden';
})

/**
 * Show / Hide Controls
 */
window.addEventListener('keypress', (event) => {
    const hint = document.getElementsByClassName('controls')

    if (event.key === 'h') {
        gui.show(gui._hidden)

        if (!gui._hidden) {
            hint[0].style.visibility = 'hidden';
        } else {
            hint[0].style.visibility = 'visible';
        }
    }
})

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Objects
 */

// Texture
const textureLoader = new THREE.TextureLoader()
const gradientTexture = textureLoader.load('textures/gradients/3.jpg')
gradientTexture.magFilter = THREE.NearestFilter

// Material
const material = new THREE.MeshToonMaterial({ color: parameters.materialColor, gradientMap: gradientTexture })

// Meshes
const objectsDistance = 4

const mesh1 = new THREE.Mesh(
    new THREE.TorusGeometry(1, 0.4, 16, 60),
    material
)
const mesh2 = new THREE.Mesh(
    new THREE.ConeGeometry(1, 2, 32),
    material
)
const mesh3 = new THREE.Mesh(
    new THREE.TorusKnotGeometry(0.5, 0.35, 100, 16),
    material
)

const mesh4 = new THREE.Mesh(
    new THREE.DodecahedronGeometry(0.9),
    material
)

mesh1.position.y = - objectsDistance * 0
mesh2.position.y = - objectsDistance * 1
mesh3.position.y = - objectsDistance * 2
mesh4.position.y = - objectsDistance * 3

mesh1.position.x = 3
mesh2.position.x = - 3
mesh3.position.x = 3
mesh4.position.x = - 3

scene.add(mesh1, mesh2, mesh3, mesh4);

const sectionMeshes = [mesh1, mesh2, mesh3, mesh4]

let particles = null
let particlesGeometry = null
let particlesMaterial = null

const generateParticles = () => {
    /**
     * Particles
     */

    // Destroy old particles
    if (particles !== null) {
        particlesGeometry.dispose();
        particlesMaterial.dispose();
        scene.remove(particles);
    }

    // Geometry
    const positions = new Float32Array(parameters.particlesCount * 3)
    
    for (let i = 0; i < parameters.particlesCount; i++) {
        positions[i * 3 + 0] = (Math.random() - 0.5) * 10
        positions[i * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance * sectionMeshes.length
        positions[i * 3 + 2] = (Math.random() - 0.5) * 10
    }
    
    particlesGeometry = new THREE.BufferGeometry()
    particlesGeometry.setAttribute(
        'position', 
        new THREE.BufferAttribute(positions, 3)
    )
    
    // Material
    particlesMaterial = new THREE.PointsMaterial({
        color: parameters.particleColor,
        sizeAttenuation: true,
        size: parameters.particleSize
    })
    
    // Points
    particles = new THREE.Points(particlesGeometry, particlesMaterial)
    scene.add(particles)
}

generateParticles();

/**
 * Controls
 */
gui.addColor(parameters, 'materialColor').onChange(() => {
        material.color.set(parameters.materialColor)
    }).name('Material Colour')

gui.addColor(parameters, 'particleColor').onChange(() => {
        generateParticles()
    }).name('Particle Colour')

gui.add(parameters, 'particlesCount').min(100).max(100000).step(100).onFinishChange(generateParticles).name('Particle Count')

gui.add(parameters, 'particleSize').min(0.01).max(0.1).step(0.01).onFinishChange(generateParticles).name('Particle Size')

/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
directionalLight.position.set(1, 1, 0)

scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Scroll
 */
let scrollY = window.scrollY
let currentSection = 0

window.addEventListener('scroll', () => {
    scrollY = window.scrollY

    const newSection = Math.round(scrollY / sizes.height)

    if (newSection !== currentSection) {
        currentSection = newSection

        gsap.to(
            sectionMeshes[currentSection].rotation,
            {
                duration: 1.5,
                ease: 'power2.inOut',
                x: '+=6',
                y: '+=3',
                z: '+=1.5'
            }
        )
    }
})

/**
 * Cursor
 */
const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) => {
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Animate camera
    camera.position.y = - scrollY / sizes.height * objectsDistance

    const parallaxX = cursor.x * 0.5
    const parallaxY = - cursor.y * 0.5
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime

    // Animate meshes
    for (const mesh of sectionMeshes) {
        mesh.rotation.x += deltaTime * 0.1
        mesh.rotation.y += deltaTime * 0.12
    }

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()